export const API_URL = process.env.REACT_APP_API_URL;
export const API_V2_URL = process.env.REACT_APP_API_V2_URL;
export const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

export const APP_URL = process.env.REACT_APP_APP_URL;
export const CRM_URL = process.env.REACT_APP_CRM_URL;
export const LOGISTICS_URL = process.env.REACT_APP_LOGISTICS_URL;
export const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;
export const NEXEN_BUCKET = process.env.REACT_APP_NEXEN_BUCKET;
export const NEXEN_BUCKET_V2 = process.env.REACT_APP_NEXEN_BUCKET_V2;
