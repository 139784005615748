import { AxiosResponse } from 'axios';
import { API_V2_URL } from '../../config/urls';

import Library from '../../shared/interfaces/library.interface';
import useRequestV2 from './request-v2';

interface GetLibrariesResponse extends AxiosResponse {
    data: {
        data: Library[];
    };
}

export default function useLibrariesHttpService() {
    const { get, downloadFile } = useRequestV2({ baseURL: API_V2_URL });

    const uri = 'library';

    const getLibraries = async (): Promise<GetLibrariesResponse> => {
        return get(uri);
    };

    const getLibrariesByRoute = async (route: string) => {
        return get(`${uri}/route/${route}`);
    };

    const downloadDocument = async (folder: string, file: string) => {
        return downloadFile(`${uri}/files/download/${folder}/${file}`);
    };

    const getItems = async (params: any) => {
        return get(`${uri}/item`, params);
    };

    const getModules = async (params: any) => {
        return get(`${uri}/module`, params);
    };

    const getBrandsByItemModule = async (id: number, params?: any) => {
        return get(`${uri}/brand/module/${id}`, params);
    };

    const getBrandsCategoriesByItemModule = async (
        id: number,
        params?: any,
    ) => {
        return get(`${uri}/brand/category/module/${id}`, params);
    };

    const getModulesByLibraryRoute = async (route: string) => {
        return get(`${uri}/module/route/${route}`);
    };

    return {
        getModulesByLibraryRoute,
        getLibrariesByRoute,
        getLibraries,
        getItems,
        getModules,
        getBrandsByItemModule,
        getBrandsCategoriesByItemModule,
        downloadDocument,
    };
}
