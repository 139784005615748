import { useEffect } from 'react';
import { CRM_URL, APP_URL } from '../../../config/urls';

function Accounts() {
    useEffect(() => {
        window.location.replace(`${CRM_URL}/login?redirectTo=${APP_URL}`);
    }, []);

    return <div />;
}

export default Accounts;
