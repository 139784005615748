/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-no-bind */
import { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import {
    Breadcrumb,
    Card,
    Col,
    Container,
    Row,
    Spinner,
} from 'react-bootstrap';
import { useInfiniteQuery } from 'react-query';
import HtmlParser from 'react-html-parser';
import useLibrariesHttpService from '../../services/http/libraries-http';
import { VideoModal } from '../VideoModal';
import useIntersectionObserver from '../../hooks/useIntersectionObserver';
import { rowsPerPage } from '../../constants/pagination';
import { LibraryTypeReference } from '../../constants/library';
import getItemFileUrl from '../../utils/getItemFileUrl';

interface Props {
    libraryId: number;
    libraryTitle: string;
    libraryDescription: string;
}

export function Video(props: Props) {
    const { getItems } = useLibrariesHttpService();

    const [expanded, setExpanded] = useState<any>({});
    const [durations, setDurations] = useState<any[]>([]);

    const loadMoreRef = useRef();

    function handleModalHidding() {
        setExpanded({});
    }

    async function loadItems({ pageParam = 0 }: any) {
        const params = {
            skip: pageParam,
            take: rowsPerPage,
            libraryType: LibraryTypeReference.Video,
            libraryId: props.libraryId,
        };

        const { data } = await getItems(params);

        return {
            data: data.data,
            currentPage: pageParam,
            pages: Math.ceil(data.total / 3),
        };
    }

    const handleDuration = ({ duration, id }: any) => {
        const minutes = Math.floor(duration / 60)
            .toString()
            .padStart(2, '0');
        const seconds = Math.floor(duration % 60)
            .toString()
            .padStart(2, '0');

        setDurations((durations) =>
            durations.concat({
                id,
                duration: `${minutes}:${seconds}`,
            }),
        );
    };

    const { data, fetchNextPage, hasNextPage, isLoading, isFetchingNextPage } =
        useInfiniteQuery('itemsGallery', loadItems, {
            getNextPageParam: (response: any) => {
                if (response.currentPage + 1 < response.pages) {
                    return response.currentPage + 1;
                }
            },
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
        });

    useIntersectionObserver({
        target: loadMoreRef,
        onIntersect: fetchNextPage,
        enabled: hasNextPage,
    });

    return (
        <>
            <Container className={'mt-5 mb-5'}>
                <Breadcrumb className={'mt-5'}>
                    <Breadcrumb.Item href="/">Biblioteca</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        {props.libraryTitle}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Col className={'pl-0 pr-0 mt-5'}>
                    <h1>{props.libraryTitle}</h1>
                    <h6>{props.libraryDescription}</h6>
                </Col>
                <Row className={'mt-4'}>
                    {data?.pages &&
                        data?.pages.map((group: any) =>
                            group?.data.map((item: any, index: any) => {
                                const findDuration = durations.find(
                                    (d) => d.id === item.id,
                                );
                                const duration =
                                    findDuration && findDuration.duration;

                                return (
                                    <Col
                                        sm
                                        xs={3}
                                        md={3}
                                        className={'mb-3'}
                                        key={index}
                                    >
                                        <Card
                                            style={{
                                                backgroundColor: '#fff',
                                                cursor: 'pointer',
                                                height: '100%',
                                            }}
                                            onClick={() => {
                                                setExpanded({
                                                    show: true,
                                                    title: item.title,
                                                    description:
                                                        item.description,
                                                    url: getItemFileUrl(item),
                                                });
                                            }}
                                        >
                                            <Card.Body>
                                                <ReactPlayer
                                                    onDuration={(duration) => {
                                                        handleDuration({
                                                            duration,
                                                            id: item.id,
                                                        });
                                                    }}
                                                    width="100%"
                                                    height="7.5vw"
                                                    controls={false}
                                                    style={{
                                                        backgroundColor: '#000',
                                                        objectFit: 'contain',
                                                    }}
                                                    url={getItemFileUrl(item)}
                                                />
                                                <Card.Title className={'mt-3'}>
                                                    {item.title}
                                                    <p>{duration}</p>
                                                </Card.Title>
                                                <Card.Text
                                                    style={{
                                                        whiteSpace:
                                                            'break-spaces',
                                                    }}
                                                >
                                                    {HtmlParser(
                                                        item.description
                                                            ? item.description
                                                                  .length > 120
                                                                ? `${item.description.substring(
                                                                      0,
                                                                      120,
                                                                  )}...`
                                                                : item.description
                                                            : '',
                                                    )}
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                );
                            }),
                        )}
                    <Col
                        md={12}
                        className="text-center"
                        ref={loadMoreRef as any}
                    >
                        {(isLoading || isFetchingNextPage) && (
                            <Col md={12} className="text-center">
                                <Spinner
                                    animation="border"
                                    className="mr-2 mt-1"
                                    size={'sm'}
                                />
                                Carregando...
                            </Col>
                        )}
                        {data?.pages[0].data.length === 0 && (
                            <Col md={12} className="text-center">
                                <strong style={{ color: '#adadad' }}>
                                    Sem itens para carregar
                                </strong>
                            </Col>
                        )}
                    </Col>
                </Row>
            </Container>
            <VideoModal
                show={expanded.show}
                title={expanded.title}
                description={expanded.description}
                url={expanded.url}
                onHide={handleModalHidding}
            />
        </>
    );
}
