import { API_V2_URL } from '../../config/urls';
import useRequest from './request';

export default class NotificationHttpService {
    private static request = useRequest({ baseURL: API_V2_URL });

    private static uri = 'notification';

    public static async readMany() {
        return this.request.get(`${this.uri}`, { take: 50, page: 1 });
    }

    public static async update(id: number) {
        return this.request.patch(`${this.uri}/${id}`);
    }

    public static async delete(id: number) {
        return this.request.del(`${this.uri}/${id}`);
    }

    public static async deleteMany() {
        return this.request.del(`${this.uri}`);
    }
}
